import { useState, FC, useEffect } from 'react'
import DropDownSelectorProps from './index.interface'
const DropDownSelector: FC<DropDownSelectorProps> = ({ parentId, isTableCell, restParameters = [], choicesList, currentChoice, setCurrentChoice, focus, setFocus, enabled = true, topMode = false }) => {

  const [hoverId, setHoverId] = useState(-1)
  const [showList, setShowList] = useState(false)
  const [location, setLocation] = useState<any>({})


  useEffect(() => {
    offsetCalculate();
  }, [])


  function handleChoiceClicked(choice: any) {
    if (setCurrentChoice) {
      setCurrentChoice(choice, ...restParameters);
      if (setFocus)
        setFocus(false)
      else
        setShowList(false)
    }
  }
  let scrolling = false;
  function offsetCalculate() {
    var parentCoordinates: any = parentId && document?.getElementById(`${parentId}`)?.getBoundingClientRect();
    setLocation((location: any) => {
      return {
        'top': parentCoordinates?.y + 30,
        'left': parentCoordinates?.x
      }
    });
  }
  function detect(e: any) {
    if (e.type === 'scroll') {
      scrolling = true;
    } else {
      if (scrolling) {
        scrolling = false;
        offsetCalculate();
      }
    }
  }

  if (isTableCell) {
    let element = document.getElementsByClassName('scroll')[0];
    element?.addEventListener('mouseup', detect);
    element?.addEventListener('scroll', detect);
  }

  window.onresize = function (event) {
    event.stopPropagation();
    if (isTableCell) {
      offsetCalculate();
    }
  }
  function handleClick() {
    if (setFocus) {
      setFocus(!focus)
    }
    else {
      if (choicesList.length > 0)
        setShowList(!showList)
    }
  }
  let className = topMode ? (showList || focus ? 'neuo drop-down-selector-container top-mode expanded'
    : 'neuo drop-down-selector-container top-mode')
    : (showList || focus ? 'neuo drop-down-selector-container expanded' : 'neuo drop-down-selector-container')

  return (
    <div onClick={() => handleClick()} className={className}>
      <span id={parentId} className={'current-choice'}>{currentChoice}</span>
      {(showList || focus) && <div className={'triangle'} />}
      {(showList || focus) && <ul className={isTableCell ? 'choices-list is-cell' : 'choices-list'} style={{ top: isTableCell ? location?.top : '125%', left: isTableCell ? location?.left : '0' }}>
        {choicesList.map((choice: any, index: any) =>
          <li onMouseEnter={() => setHoverId(index)} onMouseLeave={() => setHoverId(-1)} onClick={() => handleChoiceClicked(choice)} key={choice} className={currentChoice === choice || hoverId === index ? 'choice-on-focus' : ''}>
            <div className={'border'} />
            {choice}
          </li>
        )}
      </ul>}
    </div>
  )
}
export default DropDownSelector;
