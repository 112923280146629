import { useEffect, useRef, useState } from "react";
import mapboxgl from 'mapbox-gl';
import MapboxGeocoder from '@mapbox/mapbox-gl-geocoder';
import '@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css';
import MapboxDraw from "@mapbox/mapbox-gl-draw";
import '@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css';
import * as turf from '@turf/turf'
import { fromSquareMeterToAcres } from 'utils/unitConversions'
import {
    CircleMode,
    DragCircleMode,
    DirectMode
} from 'mapbox-gl-draw-circle';
import DrawRectangle from 'mapbox-gl-draw-rectangle-mode';
if (mapboxgl.getRTLTextPluginStatus() === 'unavailable')
    mapboxgl.setRTLTextPlugin(
        'https://api.mapbox.com/mapbox-gl-js/plugins/mapbox-gl-rtl-text/v0.2.3/mapbox-gl-rtl-text.js',
        //@ts-ignore
        null,
        localStorage.getItem('i18nextLng') === 'ar' ? true : false
    );
// eslint-disable-next-line import/no-webpack-loader-syntax
(mapboxgl as any).workerClass = require('worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker').default;
(mapboxgl as any).accessToken = process.env.REACT_APP_API_MAPTOKEN;

const useMap = (t: Function, plotsList: any, organizationDetails: any, addNewPlot: Function, enableDrawing: boolean, setEnableDrawing: Function, updatePlot: Function) => {
    const [map, setMap] = useState<any>();
    const mapInstance = useRef<any>(null);
    const mapRef = useRef<any>(null);
    const [mapLoaded, setMapLoaded] = useState<boolean>(false)
    const [controlAdded, setControlAdded] = useState<boolean>(false)
    const [center, setCenter] = useState<any>()
    const [drawingMethod, setDrawingMethod] = useState<string>("");
    const [acresUsed, setAcresUsed] = useState<number>(0);
    const drawingMode = useRef<any>(null);
    const plotsRef = useRef(null);
    const acresRef = useRef<any>(null);
    const lang = localStorage.getItem('i18nextLng') || 'en';

    var deleteUnsubmittedObserverInstance = useRef<any>(null);
    var deleteSubmittedObserverInstance = useRef<any>(null);
    var submitPlotObserverInstance = useRef<any>(null);
    var editPlotObserverInstance = useRef<any>(null);

    plotsRef.current = plotsList

    let modes = MapboxDraw.modes;
    let custom_select: any = modes.direct_select;
    let DrawPolygon: any = modes.draw_polygon
    let SimpleSelectMode: any = modes.simple_select



    DirectMode.clickInactive = () => { };
    DragCircleMode.clickInactive = () => { };
    DrawRectangle.clickInactive = () => { };
    DrawPolygon.clickInactive = () => { };
    custom_select.clickInactive = () => { };
    SimpleSelectMode.clickInactive = () => { };
    DirectMode.onClick = (state: any, e: any) => {

    }
    custom_select.onClick = (state: any, e: any) => {

    }
    SimpleSelectMode.onClick = (state: any, e: any) => {

    }
    let draw: any = new MapboxDraw({
        modes: {
            ...MapboxDraw.modes,
            draw_circle: CircleMode,
            drag_circle: DragCircleMode,
            direct_select: DirectMode,
            draw_rectangle: DrawRectangle,
            custom_select: custom_select,
            draw_polygon: DrawPolygon,
            simple_select: SimpleSelectMode
        },
        boxSelect: false,
        userProperties: true,
        styles: [
            {
                'id': 'gl-draw-polygon-fill-inactive',
                'type': 'fill',
                'filter': ['all', ['==', 'active', 'false'],
                    ['==', '$type', 'Polygon'],
                    ['!=', 'mode', 'static']
                ],
                'paint': {
                    'fill-color':
                        ['match',
                            ['get', 'user_isValidArea'],
                            'false',
                            "#ff3030",
                            'true',
                            "#fdd15a",

                     /* other */"#fdd15a"
                        ],
                    'fill-outline-color': ['match',
                        ['get', 'user_isValidArea'],
                        'false',
                        "#ff3030",
                        'true',
                        "#fdd15a",

                 /* other */"#fdd15a"
                    ],
                    'fill-opacity': 0.3
                }
            },
            {
                'id': 'gl-draw-polygon-fill-active',
                'type': 'fill',
                'filter': ['all', ['==', 'active', 'true'],
                    ['==', '$type', 'Polygon']
                ],
                'paint': {
                    'fill-color': ['match',
                        ['get', 'user_isValidArea'],
                        'false',
                        "#ff3030",
                        'true',
                        "#fdd15a",

                 /* other */"#fdd15a"
                    ],
                    'fill-outline-color': ['match',
                        ['get', 'user_isValidArea'],
                        'false',
                        "#ff3030",
                        'true',
                        "#fdd15a",

                 /* other */"#fdd15a"
                    ],
                    'fill-opacity': 0.3
                }
            },
            {
                'id': 'gl-draw-polygon-midpoint',
                'type': 'circle',
                'filter': ['all', ['==', '$type', 'Point'],
                    ['==', 'meta', 'midpoint']
                ],
                'paint': {
                    'circle-radius': 3,
                    'circle-color': "transparent"
                }
            },
            {
                'id': 'gl-draw-polygon-stroke-inactive',
                'type': 'line',
                'filter': ['all', ['==', 'active', 'false'],
                    ['==', '$type', 'Polygon'],
                    ['!=', 'mode', 'static']
                ],
                'layout': {
                    'line-cap': 'round',
                    'line-join': 'round'
                },
                'paint': {
                    'line-color': ['match',
                        ['get', 'user_isValidArea'],
                        'false',
                        "#ff3131",
                        'true',
                        '#ffc02e',

             /* other */'#ffc02e'
                    ]
                    ,
                    'line-width': 3
                }
            },
            {
                'id': 'gl-draw-polygon-stroke-active',
                'type': 'line',
                'filter': ['all', ['==', 'active', 'true'],
                    ['==', '$type', 'Polygon']
                ],
                'layout': {
                    'line-cap': 'round',
                    'line-join': 'round'
                },
                'paint': {
                    'line-color': ['match',
                        ['get', 'user_isValidArea'],
                        'false',
                        "#ff3131",
                        'true',
                        '#ffc02e',

                 /* other */'#ffc02e'
                    ],
                    'line-dasharray': [0.2, 2],
                    'line-width': 3
                }
            },

            {
                'id': 'gl-draw-polygon-and-line-vertex-stroke-inactive',
                'type': 'circle',
                'filter': ['all', ['==', 'meta', 'vertex'],
                    ['==', '$type', 'Point'],
                    ['!=', 'mode', 'static']
                ],
                'paint': {
                    'circle-radius': 8,
                    'circle-color': '#fff'
                }
            },
            {
                "id": "gl-draw-line",
                "type": "line",
                "filter": ["all", ["==", "$type", "LineString"], ["==", "active", "true"]],
                "layout": {
                    "line-cap": "round",
                    "line-join": "round"
                },
                "paint": {
                    "line-color": ['match',
                        ['get', 'user_isValidArea'],
                        'false',
                        "#ff3131",
                        'true',
                        '#ffc02e',

           /* other */'#ffc02e'
                    ],
                    "line-dasharray": [0.2, 2],
                    "line-width": 2
                }
            },
            {
                "id": "gl-draw-polygon-and-line-vertex-active",
                "type": "circle",
                "filter": ["all", ["==", "meta", "vertex"], ["==", "$type", "Point"], ["!=", "mode", "static"]
                ],
                "paint": {
                    'circle-radius': 6,
                    'circle-color': ['match',
                        ['get', 'user_isValidArea'],
                        'false',
                        "#ff3030",
                        'true',
                        "#fdd15a",
                             /* other */"#fdd15a"
                    ]
                }
            }
        ],
        defaultMode: "simple_select",
        controls: {
            trash: false
        }
    });

    const coordinatesGeocoder = function (query: any) {
        // Match anything which looks like
        // decimal degrees coordinate pair.
        const matches = query.match(
            /^[ ]*(?:Lat: )?(-?\d+\.?\d*)[, ]+(?:Lng: )?(-?\d+\.?\d*)[ ]*$/i
        );
        if (!matches) {
            return null;
        }

        function coordinateFeature(lng: any, lat: any) {
            return {
                center: [lng, lat],
                geometry: {
                    type: 'Point',
                    coordinates: [lng, lat]
                },
                place_name: 'Lat: ' + lat + ' Lng: ' + lng,
                place_type: ['coordinate'],
                properties: {},
                type: 'Feature'
            };
        }

        const coord1 = Number(matches[1]);
        const coord2 = Number(matches[2]);
        const geocodes = [];

        if (coord1 < -90 || coord1 > 90) {
            // must be lng, lat
            geocodes.push(coordinateFeature(coord1, coord2));
        }

        if (coord2 < -90 || coord2 > 90) {
            // must be lat, lng
            geocodes.push(coordinateFeature(coord2, coord1));
        }

        if (geocodes.length === 0) {
            // else could be either lng, lat or lat, lng
            geocodes.push(coordinateFeature(coord1, coord2));
            geocodes.push(coordinateFeature(coord2, coord1));
        }

        return geocodes;
    };
    // useEffect(() => {

    //     if (center && mapLoaded) {
    //         map.flyTo({
    //             center: center,
    //             zoom: 18
    //         });
    //     }
    // }, [center])
    useEffect(() => {
        if (mapRef?.current) {
            const mapVar = new mapboxgl.Map({
                container: mapRef.current,
                style: 'mapbox://styles/mapbox/satellite-streets-v12',
                center: center,
                attributionControl: false,
                zoom: 2,
                // locale: lang,
                preserveDrawingBuffer: true,
            });

            mapVar.on('idle', function () {
                mapVar.resize()
            })

            mapVar.on('load', () => {
                let labels = ['country-label', 'state-label',
                    'settlement-label', 'settlement-subdivision-label',
                    'airport-label', 'poi-label', 'water-point-label',
                    'water-line-label', 'natural-point-label',
                    'natural-line-label', 'waterway-label', 'road-label'];

                labels.forEach(label => {
                    mapVar.setLayoutProperty('country-label', 'text-field', [
                        'get',
                        `name_${lang}`
                    ]);
                });

                const geocoder = new MapboxGeocoder({
                    accessToken: mapboxgl.accessToken,
                    localGeocoder: coordinatesGeocoder as any,
                    reverseGeocode: true,
                    // types: 'country,region,place,postcode,locality,neighborhood',
                    placeholder: t('searchPlaceholder')
                });

                mapVar.dragRotate.disable();
                mapVar.touchZoomRotate.disableRotation();
                mapVar.addControl(new mapboxgl.NavigationControl(), lang === 'en' ? "top-left" : "top-right");

                let el = document?.getElementById('geocoder')
                if (el && !el?.hasChildNodes()) {
                    geocoder.addTo('#geocoder');


                    // Add geocoder result to container.
                    geocoder.on('result', (e) => {
                        if (e.result.center) {
                            setCenter(e.result.center)
                            mapVar.flyTo({
                                center: e.result.center,
                                zoom: 18
                            });
                        }
                    });

                    // Clear results container when search is cleared.
                    geocoder.on('clear', () => {

                    });
                }

                mapInstance.current = mapVar
                setMap(mapVar)
                setMapLoaded(true)
            })
        }

    }, [mapRef, organizationDetails])
    function updatePositionSuccess(position: any) {
        map.flyTo({
            center: [position.coords.longitude, position.coords.latitude],
            zoom: 18
        });
        setCenter([position.coords.longitude, position.coords.latitude]);
    }
    function updatePositionFailure(error: any) {
        setCenter([0, 0]);
    }
    function getLocation() {
        if (navigator.geolocation) {
            const options = {
                enableHighAccuracy: true,
                timeout: 10000,
            };
            navigator.geolocation.getCurrentPosition(updatePositionSuccess, updatePositionFailure, options);
        } else {
            setCenter([0, 0]);
        }
    }
    useEffect(() => {
        setDrawingMethod("simple_select")
        drawingMode.current = "simple_select";
        return (() => {
            mapInstance?.current?.removeControl(myCustomControl)
            setMap(undefined);
        })
    }, [])

    function updateArea(e: any) {
        const data = draw.getAll();
        let updatedObject = e.features[0]
        if (updatedObject?.properties?.status !== 'static') {
            let area = fromSquareMeterToAcres(turf.area(data))
            let remaining: number = Number(((organizationDetails?.allowedAcres) - (organizationDetails?.consumedAcres + area)).toFixed(2))
            if (remaining <= 0) {
                updatedObject.properties.isValidArea = 'false'
                draw.setFeatureProperty(updatedObject.id, 'isValidArea', "false")
            }
            else {
                updatedObject.properties.isValidArea = 'true'
                draw.setFeatureProperty(updatedObject.id, 'isValidArea', "true")
            }
            draw.set(draw.getAll())
            acresRef.current = area;
            setAcresUsed(area);
            updatePlot(plotsRef.current, updatedObject)
        }
    }



    function modeChanged(e: any) {
        if (e.mode === "simple_select" && drawingMode.current === "draw_polygon" && draw.getSelected()?.features?.length === 0) {
            draw.changeMode(drawingMode.current)
        }
    }


    function createArea(e: any) {
        const data = draw.getAll();
        let newObj = e.features[0]
        newObj.properties.status = 'active'
        let area = fromSquareMeterToAcres(turf.area(data))
        let remaining: number = Number(((organizationDetails?.allowedAcres) - (organizationDetails?.consumedAcres + area)).toFixed(2))
        if (remaining <= 0) {
            newObj.properties.isValidArea = 'false'
            draw.setFeatureProperty(newObj.id, 'isValidArea', "false")
        }
        else {
            newObj.properties.isValidArea = 'true'
            draw.setFeatureProperty(newObj.id, 'isValidArea', "true")
        }
        draw.set(draw.getAll())
        setAcresUsed(area);
        acresRef.current = area
        addNewPlot(newObj)
        setEnableDrawing(false)
    }

    useEffect(() => {
        if (mapLoaded && !controlAdded) {
            map.addControl(draw);
            map.addControl(myCustomControl, lang === 'en' ? "top-right" : "top-left");
            map.on('draw.create', createArea);
            map.on('draw.update', updateArea);
            map.on('draw.modechange', modeChanged);
            setControlAdded(true);
        }
    }, [mapLoaded, enableDrawing])

    useEffect(() => {
        if (mapLoaded)
            getLocation();
    }, [mapLoaded])
    class MyCustomControl {
        rectButton: any;
        circButton: any;
        pointButton: any;
        shapesContainer: any;
        draw: any;

        constructor(opt: any) {
            let ctrl = this;
            ctrl.draw = opt.draw;

        }

        onAdd(map: any) {
            let ctrl = this;
            ctrl.draw.deleteAll();
            ctrl.circButton = document.getElementById('circle-mode');
            ctrl.rectButton = document.getElementById('rectangle-mode');
            ctrl.pointButton = document.getElementById('point-mode');
            ctrl.shapesContainer = document.getElementById("shapes-container");


            ctrl.circButton.onclick = () => {
                const zoom = map.getZoom();
                ctrl.draw.changeMode("drag_circle", {
                    initialRadiusInKm: 1 / Math.pow(2, zoom - 11)
                });
                drawingMode.current = "drag_circle"
                ctrl.draw.delete("-96.5801808656236544.76489866786821");
            };

            ctrl.rectButton.onclick = () => {
                ctrl.draw.changeMode("draw_rectangle");
                drawingMode.current = "draw_rectangle"
            };

            ctrl.pointButton.onclick = () => {
                ctrl.draw.changeMode("draw_polygon");
                drawingMode.current = "draw_polygon"
            };


            const deleteUnsubmittedObserver = () => {
                const el: any = document.querySelector('#delete-unsubmitted-plot-button');
                if (el) {
                    el.addEventListener('click', (e: any) => {
                        let id = el.parentElement.id
                        ctrl.draw.delete(id);
                        const data = draw.getAll();
                        let area = fromSquareMeterToAcres(turf.area(data))
                        acresRef.current = area
                        setDrawingMethod("")
                        setAcresUsed(area);
                        setEnableDrawing(true)
                    });
                }
            };

            const deleteSubmittedObserver = () => {
                const el: any = document.querySelectorAll('#delete-submitted-plot-button');
                el?.forEach((element: any) => {
                    if (element) {
                        element.addEventListener('click', (e: any) => {
                            let id = element.parentElement.id
                            ctrl.draw.delete(id);
                            const data = draw.getAll();
                            let area = fromSquareMeterToAcres(turf.area(data))
                            acresRef.current = area
                            setAcresUsed(area);
                        });
                    }
                })

            };

            const submitObserver = () => {
                const el: any = document.querySelector('#plot-submit-button');
                if (el) {
                    let id = el.parentElement.id
                    var feat = draw.get(id);
                    // let isValidId = draw.getAll()?.features?.some((feature: any) => { return feature?.id === id });
                    if (id) {
                        if (feat?.properties?.isCircle)
                            ctrl.draw.changeMode("direct_select", { featureId: id || '' });
                        else
                            ctrl.draw.changeMode("custom_select", { featureId: id || '' });
                        el.addEventListener('click', (e: any) => {
                            var currFeat = draw.get(id);
                            ctrl.draw.setFeatureProperty(id, 'active', "false")
                            draw.delete(id)
                            draw.add(currFeat)
                            ctrl.draw.changeMode("simple_select")
                            drawingMode.current = "simple_select"
                            setDrawingMethod("simple_select")
                        })
                    }
                }
            };

            const editObserver = () => {
                const el: any = document.querySelector('#edit-submitted-plot-button');
                if (el) {
                    let id = el.parentElement.id
                    el.addEventListener('click', (e: any) => {
                        var feat = draw.get(id);
                        if (feat.properties.isCircle) {
                            drawingMode.current = "drag_circle"
                            ctrl.draw.changeMode("direct_select", { featureId: id });
                        }
                        else
                            ctrl.draw.changeMode("custom_select", { featureId: id });
                    })
                }
            };
            deleteUnsubmittedObserverInstance.current = new MutationObserver(deleteUnsubmittedObserver);
            deleteUnsubmittedObserverInstance.current.observe(document.body, { subtree: true, childList: true });

            deleteSubmittedObserverInstance.current = new MutationObserver(deleteSubmittedObserver);
            deleteSubmittedObserverInstance.current.observe(document.body, { subtree: true, childList: true });

            submitPlotObserverInstance.current = new MutationObserver(submitObserver);
            submitPlotObserverInstance.current.observe(document.body, { subtree: true, childList: true });

            editPlotObserverInstance.current = new MutationObserver(editObserver);
            editPlotObserverInstance.current.observe(document.body, { subtree: true, childList: true });

            ctrl.shapesContainer.className = "mapboxgl-ctrl-group mapboxgl-ctrl shapes-buttons-container";
            ctrl.shapesContainer.style.visibility = "visible"
            return ctrl.shapesContainer;
        }
        onRemove(map: any) {
            submitPlotObserverInstance.current.disconnect();
            deleteSubmittedObserverInstance.current.disconnect();
            editPlotObserverInstance.current.disconnect();
            deleteUnsubmittedObserverInstance.current.disconnect();
        }

    }
    const myCustomControl = new MyCustomControl({ draw: draw });
    return { drawingMethod, acresUsed, setDrawingMethod, setEnableDrawing, mapRef }
}


export default useMap;
