import BadgeComponent from 'components/BadgeComponent';
import { FC } from 'react'
import { notify } from 'assets/icons/svg/constants';
import { point, circular, rectangular } from 'assets/icons/svg/FarmRegistration/index'
import { Player } from '@lottiefiles/react-lottie-player';
import CircleGif from 'assets/animations/circle.json'
import RectangleGif from 'assets/animations/rectangle.json'
import PointGif from 'assets/animations/points.json'
import { MapViewProps } from './index.interface';
import ContactUs from 'components/ContactUs';

const MapView: FC<MapViewProps> = ({ t, mapRef, acresUsed, organizationDetails, enableDrawing, drawingMethod, setDrawingMethod }) => {

    let remaining: any = Number((organizationDetails?.allowedAcres - (organizationDetails?.consumedAcres + acresUsed))).toFixed(2) || 0

    return (
        <div className='farm-registration-map-container'>
            <div className={enableDrawing?'map':'map disable-drawing'} ref={mapRef} id='farm-registration-map' />
            <div id='geocoder' />
            <BadgeComponent
                badgeIcon={notify(remaining <= 0 ? '#ffb9b9' : "#165f59")}
                badgeText={<>{t('totalAvailableAcres')} <span style={{ fontFamily: 'nunito_extraBold' }}>- {t('acresCount', { value: (remaining > 0 ? remaining : 0), total: organizationDetails?.allowedAcres })}</span></>}
                backgroundColor={remaining <= 0 ? "#F53434CC" : "#FFFFFFD9"}
                wrapperBackgroundColor={remaining <= 0 ? "#EA474787" : "#FFFFFF59"}
                textColor={remaining <= 0 ? "#FFB9B9" : "#165F59"}
                borderColor={remaining <= 0 ? "#FFB5B4" : "#FFFFFF"}
            />
            <div id={'shapes-container'} className='shapes-buttons-container'>
                <button id={'rectangle-mode'} disabled={!enableDrawing} onClick={() => setDrawingMethod('draw_rectangle')} className={drawingMethod === 'draw_rectangle' ? 'selected' : ''}>{rectangular}
                    <div className='triangle' />
                    <div className='animation-tooltip'>
                        <Player
                            src={RectangleGif}
                            className={'gif'}
                            background={'#156E67'}
                            keepLastFrame={false}
                            autoplay
                            loop
                        />
                        <div className='text'>{t('rectangularShapeDesc')}</div>
                    </div>
                </button>
                <button id={'circle-mode'} disabled={!enableDrawing} onClick={() => setDrawingMethod('drag_circle')} className={drawingMethod === 'drag_circle' ? 'selected' : ''}>{circular}
                    <div className='triangle' />
                    <div className='animation-tooltip'>
                        <Player
                            src={CircleGif}
                            className={'gif'}
                            background={'#156E67'}
                            keepLastFrame={false}
                            autoplay
                            loop
                        />
                        <div className='text'>{t('circularShapeDesc')}</div>
                    </div>
                </button>
                <button id={'point-mode'} disabled={!enableDrawing} onClick={() => setDrawingMethod('draw_polygon')} className={drawingMethod === 'draw_polygon' ? 'selected' : ''}>{point}
                    <div className='triangle' />
                    <div className='animation-tooltip'>
                        <Player
                            src={PointGif}
                            className={'gif'}
                            background={'#156E67'}
                            keepLastFrame={false}
                            autoplay
                            loop
                        />
                        <div className='text'>{t('pointShapeDesc')}</div>
                    </div>
                </button>
                <div className='contact-us-wrapper'>
                    <ContactUs />
                </div>
            </div>
        </div>
    )
}

export default MapView
