import { get, post } from '../../../../../services/ApiFetching';
export const getCrops = async () => {
    const response = await get(`/crops/list`);
    return response;
};

export const defineFarm = async (farmObject: any) => {
    const response = await post(`/farms/add`, farmObject);
    return response;
};

