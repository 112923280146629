import React, { FC, useState } from "react";
import { proceedArrow, proceedArrowAr } from '../../assets/icons/svg/constants/index'
import {ProceedButtonProps} from './index.interface'
const locale = localStorage.getItem('i18nextLng')

const ProceedButton: FC<ProceedButtonProps> = ({ title,handleClick,disabled }) => {
    const [hover, setHover] = useState(false)

    return (
        <button style={{opacity:disabled?0.2:1, cursor:disabled? 'not-allowed' :'pointer'}} onClick={disabled?undefined:handleClick} onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)} 
        className={disabled?'proceed-button disabled':'proceed-button'}>
            {title}
            <span className='proceed-icon'>{locale === 'ar' ? (hover && !disabled ? proceedArrowAr('#C4FFF9', '322', '321') : proceedArrowAr())
                : (hover && !disabled ? proceedArrow('#C4FFF9', '322', '321') : proceedArrow())}</span>
        </button>
    );
}

export default ProceedButton