import { getEnv } from 'env';

export const getCookie = (name: string) => {
  return document.cookie
    .split("; ")
    .find((row) => row.startsWith(`${name}=`))
    ?.split("=")[1];
};

const getLoggedIn = () => {
  return getCookie(getEnv().COOKIE_LOGIN_PROPERTY) ? true : false;
}
export const isLoggedIn = getLoggedIn();

const getLink = () => {
  const currentPath = window.location.href;
  return `${getEnv().NAMAA_LOGIN_URL}?redirect_to=${encodeURIComponent(new URL(currentPath).origin)}`
}
export const loginLink = getLink();

export const getEmail = () => {
  return JSON.parse(localStorage.getItem('userDetails')!)?.email;
}

export const logoutLink = getEnv().NAMAA_LOGOUT;


