import React, { useEffect, useState } from "react";
import { useLocation } from 'react-router-dom';
import * as turf from '@turf/turf'
import { getCrops, defineFarm } from "./api";
import useHandleErrorHook from "utils/useHandleErrorHook";
import { fromSquareMeterToAcres } from 'utils/unitConversions'
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux'
import { ReduxState } from 'store'
import { get } from 'services/ApiFetching';
import { DEFINE_FARM } from "utils/statusCodes";
import { purifyText } from "utils/textUtil";
import { IOption } from "components/SearchDropDownList/index.interface";
import { isLoggedIn } from "utils/SSOUtil";

const usePanel = (t: Function, selectedOrganization: any) => {
    const { state } = useLocation();
    const [currentFarmName, setCurrentFarmName] = useState(state?.name);
    const [organizationId, setOrganizationId] = useState(selectedOrganization?.id);
    const [farmLocation, setFarmLocation] = useState<string>("");
    const [farmDescription, setFarmDescription] = useState<string>("");
    const [plotsList, setPlotsList] = useState<any>([]);
    const [cropsList, setCropsList] = useState<any>([]);
    const [showMoreLanguages, setShowMoreLanguages] = useState(false);
    const { handleErrorHelper } = useHandleErrorHook()
    const [enableDrawing, setEnableDrawing] = useState(true);
    const [defineClicked, setDefineClicked] = useState(false);
    const [responseMessage, setResponeMessage] = useState<any>();

    const navigate = useNavigate();

    let isLogged = useSelector((reduxState: ReduxState) => reduxState.isLogged)
    const dispatch = useDispatch()
    const setPermissionsState = (state: any) => dispatch({ type: 'set', permissions: state })

    async function getAllPermissions() {
        const response = await get(`/auth/permissions`);
        return response;
    };



    const [soilTypes, setSoilTypes] = useState(["sandy soil",
        "loamy sand soil",
        "sandy loam soil",
        "medium loam soil",
        "silty loam soil",
        "silty soil",
        "sandy clay loam soil",
        "clay loam soil",
        "silty clay loam soil",
        "sandy clay soil",
        "clay soil",
        "silty clay soil"]);
    const [selectedSoilType, setSelectedSoilType] = useState<any>("sandy soil");


    function handleSelectedSoil(id: string, choice: string) {
        if (id) {
            let plotIndex = plotsList.findIndex((p: any) => p.id === id);
            let plot = plotsList[plotIndex]
            let currPlots = [...plotsList]
            plot.properties.soilType = choice
            currPlots[plotIndex] = plot
            setPlotsList(currPlots)
        }
        else {
            setSelectedSoilType(choice)
        }
    }
    function sendToModalFunction(farmName: string) {
        setCurrentFarmName(purifyText(farmName))
    }

    function handleFarmLocationChange(location: string) {
        setFarmLocation(purifyText(location))
    }

    function handleFarmDescriptionChange(description: string) {
        setFarmDescription(purifyText(description))
    }

    function updatePlot(plots: any, updatedObj: any) {
        let currentPlots = [...plots]
        let plotIndex = currentPlots.findIndex((p: any) => p.id == updatedObj.id);
        let plot = { ...updatedObj }
        plot.properties = { ...currentPlots[plotIndex].properties }
        var polygon = turf.polygon(updatedObj.geometry.coordinates);
        var centroid: any = turf.centroid(polygon);
        plot.properties.centerX = centroid.geometry.coordinates[0]
        plot.properties.centerY = centroid.geometry.coordinates[1]
        let area = turf.area(updatedObj)
        area = fromSquareMeterToAcres(area)
        plot.properties.totalAcreage = area.toFixed(2)
        plot.properties.isValidArea = updatedObj.properties.isValidArea;

        plot.properties.center = updatedObj.properties.center;
        plot.properties.radiusInKm = updatedObj.properties.radiusInKm;

        currentPlots[plotIndex] = plot
        setPlotsList(currentPlots)
    }
    function addNewPlot(newObj: any) {
        var polygon = turf.polygon(newObj.geometry.coordinates);
        var centroid: any = turf.centroid(polygon);
        newObj.properties.centerX = centroid.geometry.coordinates[0]
        newObj.properties.centerY = centroid.geometry.coordinates[1]
        let area = turf.area(newObj)
        area = fromSquareMeterToAcres(area)
        newObj.properties.totalAcreage = area.toFixed(2)
        newObj.properties.cropId = cropsList[0].value
        newObj.properties.soilType = soilTypes[0]
        newObj.properties.plotType = "drip"
        setShowMoreLanguages(false)
        setPlotsList((plots: any) => [...plots, newObj])
        let objDiv: any = document.getElementById("panel-content-wrapper");
        objDiv.scrollTop = objDiv.scrollHeight;
    }

    function handlePlotNameChange(id: string, value: string, lang: string) {
        let plotIndex = plotsList.findIndex((p: any) => p.id === id);
        let plot = plotsList[plotIndex]
        let currPlots = [...plotsList]

        if (plot) {
            if (lang === 'ar') {
                plot.properties.nameAr = purifyText(value)
                if (!showMoreLanguages)
                    plot.properties.nameEn = purifyText(value)
            }
            else {
                plot.properties.nameEn = purifyText(value)
                if (!showMoreLanguages)
                    plot.properties.nameAr = purifyText(value)
            }
            currPlots[plotIndex] = plot
            setPlotsList(currPlots)
        }
    }

    function handlePlotTypeSelectionChange(id: string, value: number) {
        let plotIndex = plotsList.findIndex((p: any) => p.id === id);
        let plot = plotsList[plotIndex]
        let currPlots = [...plotsList]
        if (value === 0)
            plot.properties.plotType = "drip"
        else
            plot.properties.plotType = "pivot"
        currPlots[plotIndex] = plot
        setPlotsList(currPlots)
    }

    function handlePlotDelete(id: string) {
        let currPlots = [...plotsList]
        currPlots = currPlots.filter((plot: any) => plot.id !== id)
        setShowMoreLanguages(false)
        setPlotsList(currPlots)
    }
    function handleSubmitPlot(id: string) {
        let plotIndex = plotsList.findIndex((p: any) => p.id === id);
        let plot = plotsList[plotIndex]
        let currPlots = [...plotsList]
        plot.properties.status = "static"
        plot.properties.isUpdating = false
        currPlots[plotIndex] = plot
        setPlotsList(currPlots)
        setEnableDrawing(true)
        setShowMoreLanguages(false)
    }

    async function handleDefineFarm() {
        setDefineClicked(true)
        let map: any = {
            type: "FeatureCollection",
            features: plotsList.map((plot: any) => {
                delete plot.id
                return plot
            })
        }
        var centroid: any = turf.centroid(map);
        let area = turf.area(map)
        area = fromSquareMeterToAcres(area)
        let requestBody = {
            organizationId: organizationId,
            nameEn: currentFarmName,
            nameAr: currentFarmName,
            descriptionEn: farmDescription,
            descriptionAr: farmDescription,
            addressEn: farmLocation,
            addressAr: farmLocation,
            totalAcreage: area.toFixed(2),
            longitude: centroid.geometry.coordinates[0].toString(),
            latitude: centroid.geometry.coordinates[1].toString(),
            map: map
        }
        const response = await defineFarm(requestBody);
        if (response?.data?.statusCode === DEFINE_FARM.DEFINE_FARM_SUCESS) {
            setResponeMessage({
                type: "success",
                messageTitle: t('success'),
                messageSubtitle: t('farmAddSuccess')
            })

            if (isLoggedIn) {
                getAllPermissions().then(result => {
                    if (result.data.statusCode === 200) {
                        setPermissionsState(result.data.payload)
                    }
                })
            }
            setTimeout(() => {
                setResponeMessage(undefined);
                navigate('/UserFarms', { state: { scrollToEnd: true, farmId: response?.data.payload } })
                // window.location.reload();
            }, 1500)
        }
        else
            if (response.data.statusCode === DEFINE_FARM.ORGANIZATION_CAPACITY_EXCEEDED) {
                setResponeMessage({
                    type: "failed",
                    messageTitle: t('calendarFailedProcess'),
                    messageSubtitle: t('farmLimitError')
                })
                setTimeout(() => {
                    setResponeMessage(undefined);
                }, 1500);
            }
            else
                if (response.data.statusCode === DEFINE_FARM.DUPLICATE_FARM_EXIST) {
                    setResponeMessage({
                        type: "failed",
                        messageTitle: t('calendarFailedProcess'),
                        messageSubtitle: t('duplicateFarmError')
                    })
                    setTimeout(() => {
                        setResponeMessage(undefined);
                    }, 1500);
                }
                else {
                    setResponeMessage({
                        type: "failed",
                        messageTitle: t('calendarFailedProcess'),
                        messageSubtitle: t('farmAddFailure')
                    })
                    setTimeout(() => {
                        setResponeMessage(undefined);
                    }, 1500);
                }

        setDefineClicked(false)
    }

    function handleShowMoreLanguagesToggle(id: string, lang: string) {
        let plotIndex = plotsList.findIndex((p: any) => p.id === id);
        let plot = plotsList[plotIndex]
        let currPlots = [...plotsList]

        if (!showMoreLanguages) {
            setShowMoreLanguages(true)
        }
        else {
            if (lang === 'ar')
                plot.properties.nameEn = plot.properties.nameAr
            else
                plot.properties.nameAr = plot.properties.nameEn
            setShowMoreLanguages(false)
            currPlots[plotIndex] = plot
            setPlotsList(currPlots)
        }
    }

    function handleSelectedCrop(id: string, cropSelected: IOption) {
        let plotIndex = plotsList.findIndex((p: any) => p.id === id);
        let plot = plotsList[plotIndex]
        let currPlots = [...plotsList]
        let cropId = cropSelected.value;
        plot.properties.cropId = cropId
        currPlots[plotIndex] = plot
        setPlotsList(currPlots)
    }

    function handlePlotEdit(id: string) {
        setEnableDrawing(false)
        let plotIndex = plotsList.findIndex((p: any) => p.id === id);
        let plot = plotsList[plotIndex]
        let currPlots = [...plotsList]
        plot.properties.status = 'active'
        plot.properties.isUpdating = true
        currPlots[plotIndex] = plot
        setPlotsList(currPlots)
        if (plot.properties.nameEn !== plot.properties.nameAr)
            setShowMoreLanguages(true)
    }

    function disableDefineFarm() {
        if (defineClicked || plotsList.length === 0 || !farmLocation || !farmDescription || !currentFarmName || plotsList.some((plot: any) => plot.properties.status === 'active'))
            return true
        return false
    }
    const getAllCrops = async () => {
        try {
            const allCropsResponse: any = await getCrops();
            if (allCropsResponse?.data?.statusCode === 200) {
                const formattedCrops = allCropsResponse.data.payload.map((crop: any) => {
                    return {
                        value: crop.id,
                        text: crop.name,
                        selected: false
                    }
                });
                setCropsList(formattedCrops)
            }
        }
        catch (error) {
            handleErrorHelper(error)
        }

    }

    useEffect(() => {
        getAllCrops();
        //eslint-disable-next-line
    }, [])

    return {
        currentFarmName, showMoreLanguages, cropsList, handleSelectedCrop, handleShowMoreLanguagesToggle, sendToModalFunction,
        handlePlotTypeSelectionChange, plotsList, farmDescription, handleFarmDescriptionChange, disableDefineFarm, handlePlotDelete,
        handlePlotNameChange, handleFarmLocationChange, addNewPlot, handleSubmitPlot, handleDefineFarm, enableDrawing, setEnableDrawing,
        selectedSoilType, soilTypes, handleSelectedSoil, handlePlotEdit, updatePlot, responseMessage, setResponeMessage
    };
}


export default usePanel;
