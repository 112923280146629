//@ts-nocheck
import { BASE_API } from '../config'
import { logoutLink } from 'utils/SSOUtil';

const headerObj = {
  'Client': 'webapp',
  'Content-Type': 'application/json',
}

class UnauthorizedError extends Error {
  constructor(message) {
    super(message);
    this.code = 401;
  }
}
class UnsubscribedError extends Error {
  constructor(message) {
    super(message);
    this.code = 402;
  }
}

export const get = async (url: string, timeout = 40000, responseType?: string) => {
  let farmId = JSON.parse(localStorage.getItem('farmState')!)?.farmId
  let orgId = JSON.parse(localStorage.getItem('orgState')!)?.id
  let headers = {
    ...headerObj, 'Accept-Language': localStorage.getItem('i18nextLng'),
  }
  if (farmId) {
    headers = { ...headers, 'farm-id': farmId }
  }
  if (orgId) {
    headers = { ...headers, 'organization-id': orgId }
  }
  try {
    return await BASE_API.get(url, {
      timeout: timeout,
      headers: headers,
      withCredentials: true,
      responseType: responseType || 'json'
    });
  }
  catch (error) {
    if (error?.response?.status === 401) {
      throw new UnauthorizedError(error)
    }
    else {
      if (error?.response?.status === 402) {
        throw new UnsubscribedError(error);
      }
      else
        throw new Error(error)
    }
  }
};

export const post = async (url: string, body?: any, responseType = 'json', timeout = 40000) => {
  try {
    let farmId = JSON.parse(localStorage.getItem('farmState')!)?.farmId
    let orgId = JSON.parse(localStorage.getItem('orgState')!)?.id
    let headers = {
      ...headerObj, 'Accept-Language': localStorage.getItem('i18nextLng'),
    }
    if (farmId) {
      headers = { ...headers, 'farm-id': farmId }
    }
    if (orgId) {
      headers = { ...headers, 'organization-id': orgId }
    }
    return await BASE_API.post(url, body, {
      headers: headers,
      timeout: timeout,
      withCredentials: true,
      responseType: responseType || 'json'
    });
  }
  catch (error) {
    console.log('error is thrown from post api function')
    if (error?.response?.status === 401) {
      throw new UnauthorizedError(error)
    }
    else {
      if (error?.response?.status === 402)
        throw new UnsubscribedError(error)
      throw new Error(error)
    }
  }
};

export const deleteRequest = async (url: string) => {
  try {
    let farmId = JSON.parse(localStorage.getItem('farmState')!)?.farmId
    let orgId = JSON.parse(localStorage.getItem('orgState')!)?.id
    let headers = {
      ...headerObj, 'Accept-Language': localStorage.getItem('i18nextLng'),
    }
    if (farmId) {
      headers = { ...headers, 'farm-id': farmId }
    }
    if (orgId) {
      headers = { ...headers, 'organization-id': orgId }
    }

    return await BASE_API.delete(url, {
      headers: headers,
      timeout: 40000,
      withCredentials: true
    });
  }
  catch (error) {
    console.log('error is thrown from delete api function')
    if (error.response?.status !== 401) {
      throw new Error(error)
    }
    else {
      if (error?.response?.status === 402) {
        throw new UnsubscribedError(error)
      }
      throw new UnauthorizedError(error)
    }
  }
};



BASE_API.interceptors.response.use(

  (res) => {
    return res;
  },
  async (err) => {

    const originalConfig = err.config;

    if (err.response) {
      console.log('error', err)
      if ((err.response.status === 401) && !originalConfig._retry) {
        originalConfig._retry = true;
        let farmId = JSON.parse(localStorage.getItem('farmState')!)?.farmId
        let orgId = JSON.parse(localStorage.getItem('orgState')!)?.id
        let headers = {
          ...headerObj, 'Accept-Language': localStorage.getItem('i18nextLng'),
        }
        if (farmId) {
          headers = { ...headers, 'farm-id': farmId }
        }
        if (orgId) {
          headers = { ...headers, 'organization-id': orgId }
        }
        try {
          if (originalConfig.url !== "/auth/v2/refreshtoken") {
            const rs = await BASE_API.post("/auth/v2/refreshtoken", {}, {
              headers: headers,
              timeout: 40000,
              withCredentials: true
            });
            return BASE_API(originalConfig);
          }
          else {
            window.open(logoutLink, '_self', 'noreferrer');
            localStorage.clear();
          }
        } catch (_error) {

          return Promise.reject(_error);
        }
      }
      else {
        if (err.response.status === 402) {
          localStorage.removeItem('farmState');
          window.location.replace('/#/subscription-ended')
        }
      }
    }

    return Promise.reject(err);
  }
);

