
import { FC } from 'react'
import { confirm, note } from 'assets/icons/svg/ManualInput/index'
import Input from 'components/Input';
import { plotIcon, deleteIcon } from 'assets/icons/svg/FarmRegistration/index';
import Checkbox from 'components/Checkbox';
import { UnsubmittedPlotProps } from './index.interface';
import DropDownSelector from 'components/DropDownSelector';
import RadioButton from 'components/RadioButton';
import translateNumbers from 'utils/translateNumbers';
import SearchDropDownList from 'components/SearchDropDownList';
import { cropsIcon } from 'assets/icons/svg/FarmRegistration/index';
import { IOption } from 'components/SearchDropDownList/index.interface';

const UnsubmittedPlot: FC<UnsubmittedPlotProps> = ({ t, soilTypes, handleSelectedSoil, handlePlotDelete, handleSubmitPlot, handleSelectedCrop, cropsList, showMoreLanguages, handleShowMoreLanguagesToggle, plot, index, handlePlotTypeSelectionChange, handlePlotNameChange }) => {
    const locale = localStorage.getItem('i18nextLng')
    let cropsNames = cropsList?.map((crop: any) => crop.name) || []
    let selectedCropName = cropsList?.find((crop: any) => crop.id === plot?.properties.cropId)?.name || ""
    let disableSubmit = !plot.properties.nameEn || plot.properties.nameEn.length === 0 || (showMoreLanguages && locale === 'en' && !plot.properties.nameAr) || (showMoreLanguages && locale === 'ar' && !plot.properties.nameEn) || plot.properties.isValidArea === 'false'
    const tempIndex = cropsList?.findIndex((crop: IOption) => crop.value === plot?.properties.cropId) || 0;
    return (
        <div className='unsubmitted-plot-wrapper' id={plot.id}>
            <div className='card-wrapper'>
                <div className='plot-card-title ' id={plot.id}>
                    <span>{t('plot')} #{translateNumbers((index + 1).toString())}</span>
                    <button
                        id={"delete-unsubmitted-plot-button"}
                        className="delete-button"
                        onClick={() => {
                            handlePlotDelete(plot.id)
                        }}
                    >
                        <span className='icon'>{deleteIcon}</span>
                    </button>
                </div>
                <div className='plot-card-body'>
                    <Input inputType={'text'} title={t('plotName')} defaultValue={locale === 'en' ? plot.properties.nameEn : plot.properties.nameAr}
                        handleChange={(e: any) => handlePlotNameChange(plot.id, e.target.value, locale)} maxLength={40} iconImage={plotIcon}
                    />
                    <div className='another-languages-container'>
                        <Checkbox checked={showMoreLanguages}
                            title={t('plotLangDefinition')}
                            action={() => handleShowMoreLanguagesToggle(plot.id, locale)} />
                        {(showMoreLanguages || (plot.properties.nameEn !== plot.properties.nameAr)) && <div className='language-options-view'>
                            <span className='note'>{note}{t('namingNote')}</span>
                            <div className='language-row'>
                                <DropDownSelector choicesList={[locale === 'en' ? 'العربية' : 'English']} currentChoice={locale === 'en' ? 'العربية' : 'English'} setCurrentChoice={(choice: string) => console.log('')} />
                                <Input inputType={'text'} title={t('plotName')} iconImage={plotIcon} defaultValue={plot.properties.isUpdating ? (locale === 'en' ? plot.properties.nameAr : plot.properties.nameEn) : ''}
                                    handleChange={(e: any) => handlePlotNameChange(plot.id, e.target.value, locale === 'en' ? 'ar' : locale)} maxLength={40}
                                />
                                <button
                                    className="delete-button"
                                    onClick={() => {
                                        handleShowMoreLanguagesToggle(plot.id, locale)
                                    }}
                                >
                                    {deleteIcon}
                                </button>
                            </div>
                        </div>}

                    </div>
                    <div className='info-key'>
                        {t('calculatedDimension')}
                    </div>
                    <div className='info-value' style={{ color: plot.properties.isValidArea === 'false' ? '#FC6F78' : '#46AAA0' }}>
                        {plot.properties.totalAcreage} {t('acres')}
                    </div>
                    <div className='info-key'>
                        {t('farmType')}
                    </div>
                    <div className='info-value select-container'>
                        <RadioButton
                            options={[t('drip'), t('pivot')]}
                            handleSelection={(v: any) => { handlePlotTypeSelectionChange(plot.id, v) }}
                            currentSelection={plot.properties.plotType === 'pivot' ? 1 : 0}
                        />
                    </div>
                    <div className='info-key'>
                        {t('cropTypeTitle')}
                    </div>
                    <div className='info-value crop-select'>
                        <SearchDropDownList
                            options={cropsList}
                            onChangeHandler={(option: IOption[]) => handleSelectedCrop(plot.id, option[0])}
                            valueIcon={cropsIcon}
                            selectedValue={cropsList[tempIndex].text}
                            valueWidth='10rem'
                        />
                    </div>
                    {/* <div className='info-value crop-select'>
                        <DropDownSelector choicesList={cropsNames} currentChoice={selectedCropName} setCurrentChoice={(choice: string) => handleSelectedCrop(plot.id, choice)} />
                    </div> */}
                    {/* <div className='info-key'>
                        {t('soilType')}
                    </div> */}
                    {/* <div className='info-value soil'>
                        <DropDownSelector choicesList={soilTypes} currentChoice={plot.properties.soilType} setCurrentChoice={(choice: string) => handleSelectedSoil(plot.id, choice)} />
                    </div> */}
                </div>
            </div>
            <button
                id="plot-submit-button"
                className="submit-button"
                disabled={disableSubmit}
                onClick={() => {
                    handleSubmitPlot(plot.id)
                }}
            >
                <span className='icon'>{confirm}</span>
            </button>
        </div>
    )
}

export default UnsubmittedPlot
