import { useTranslation } from 'react-i18next';
import useHandleErrorHook from 'utils/useHandleErrorHook';
import { post } from 'services/ApiFetching';

const useSwitchLang = (setLoading: Function | undefined) => {
  const { handleErrorHelper } = useHandleErrorHook();
  const { t, i18n } = useTranslation();
  let chooseLang = async (lng: string) => {
    const chosenLang = lng === t('constantArabicTrans') ? "ar" : "en";
    if (chosenLang !== localStorage.getItem('i18nextLng')) {
      if (setLoading) setLoading(true);
      try {
        const response = await post(`/auth/updatelanguage`, { language: chosenLang });
        if (response.data?.statusCode === 200) {
          const dir = chosenLang === 'ar' ? "rtl" : "ltr"
          localStorage.setItem('i18nextLng', chosenLang);
          document.documentElement.dir = dir
          i18n.changeLanguage(chosenLang);
          window.location.reload();
        }
      } catch (error) {
        handleErrorHelper(error)
      }
    }
  }
  return { t, chooseLang };

}

export default useSwitchLang;