import React, { FC, useEffect, useMemo, useState } from 'react'
import {
  CForm,
  CInput,
  CInputGroup,
  CInputGroupPrepend,
  CInputGroupText,
  CTextarea
} from '@coreui/react'
import CIcon from '@coreui/icons-react'
interface Props {
  title: string
  handleChange: any
  icon?: any,
  autoComplete?: any
  inputType: any,
  iconImage?: any,
  maxLength?: any,
  inputFocusPaddingInlineStart?: any
  focus?: any,
  isTextArea?: boolean,
  prefix?: boolean,
  defaultValue?: any,
  error?: any,
  disabled?: boolean,
  screen?: string,
  keyValue?: any,
  value?: any,
  handleFocus?: Function | any,
  handleBlur?: Function | any,
  textLength?: 'short' | 'medium' | 'large'
}

const Input: FC<Props> = ({ handleBlur, screen, title, handleChange, autoComplete, icon, inputType, iconImage, maxLength, defaultValue,
  inputFocusPaddingInlineStart = '', focus = false, isTextArea, prefix = false, error = false, disabled = false, value, handleFocus, textLength }) => {
  const [inputFocus, setInputFocus] = useState(focus)
  const [errorInput, setErrorInput] = useState(error)
  const [currentDefaultValue, setCurrentDefaultValue] = useState(defaultValue)
  const textmaxLength = {
    short: 10,
    medium: 40,
    large: 200
  }
  useEffect(() => {
    setErrorInput(error)
    setCurrentDefaultValue(defaultValue)
  }, [error, defaultValue])
  let classNames = isTextArea ? (inputFocus || (document.getElementById("text-area") as any)?.value ? 'mb-4 text-area focus' : 'mb-4 text-area') : 'mb-4'

  const renderInnerInput = useMemo(() => {
    return (<CInputGroup className={classNames} onFocus={handleFocus} onBlur={handleBlur}>
      {isTextArea ?
        <CTextarea id="text-area" defaultValue={currentDefaultValue} style={{ resize: 'none', borderBottom: errorInput === true ? '2px solid #F48585' : '2px solid #C4FFF92E' }}
          onBlur={() => setInputFocus(false)} onFocus={() => setInputFocus(true)} placeholder={title}
          onChange={handleChange} type={inputType} autoComplete={autoComplete} maxLength={textmaxLength[textLength!] ? textmaxLength[textLength!] : maxLength} />
        : <CInput style={prefix ? { textIndent: '45px' } : errorInput ? { borderBottomColor: '#F48585' } : {}}
          onBlur={() => setInputFocus(false)} defaultValue={currentDefaultValue} onFocus={() => setInputFocus(true)}
          placeholder={title} onChange={handleChange} type={inputType} autoComplete={autoComplete}
          maxLength={textmaxLength[textLength!] ? textmaxLength[textLength!] : maxLength} disabled={disabled} value={value} />
      }
      <span className={`${isTextArea && inputFocus || isTextArea && defaultValue ? 'padding-bottom-20' : ''} 
        ${errorInput ? 'error-color' : (inputFocus ? 'focus-color' : 'default-color')}
        ${icon == undefined && !inputFocus ? 'default-input-focus-padding-inline' : ''}`}
        style={{ paddingInlineStart: inputFocusPaddingInlineStart ? inputFocusPaddingInlineStart : '' }} >{title}</span>
      {(icon || iconImage) && <CInputGroupPrepend>
        <CInputGroupText>
          {icon !== undefined ? <CIcon name={icon} /> : iconImage}
        </CInputGroupText>
      </CInputGroupPrepend>}
    </CInputGroup>)
  }, [currentDefaultValue, errorInput, inputFocus, screen])
  return (
    <CForm>
      {screen === 'calendar-component' ?
        <div key={currentDefaultValue}>{renderInnerInput}</div>
        : renderInnerInput}
    </CForm>

  )
}
export default Input
