//@ts-nocheck
import 'react-app-polyfill/ie11' // For IE 11 support
import 'react-app-polyfill/stable'
import './polyfill'
import React from 'react'
import ReactDOM from 'react-dom'
import App from './App';
import * as serviceWorker from './serviceWorker'
import { I18nextProvider } from "react-i18next";
import i18n from "../src/locales/I18n";
import { icons } from './assets/icons'
import { Provider } from 'react-redux'
import store from './store'
import PWAPrompt from 'react-ios-pwa-prompt';

React.icons = icons

ReactDOM.render(
  <React.StrictMode>
    <I18nextProvider i18n={i18n}>
      <Provider store={store}>
        <App />
        <PWAPrompt
          promptOnVisit={1}
          timesToShow={10}
          copyClosePrompt="Close"
          permanentlyHideOnDismiss={false}
        />
      </Provider>
    </I18nextProvider>,
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register()
