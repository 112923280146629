import { FC, useEffect, useState } from 'react'
import MapView from './partials/MapView'
import PanelView from './partials/PanelView'
import useMap from './partials/MapView/hooks/useMap'
import { useTranslation } from 'react-i18next';
import usePanel from './partials/PanelView/hooks/usePanel';
import Modal from 'components/Modal';
import ProceedButton from 'components/ProceedButton';
import { useNavigate } from 'react-router';
import warningIcon from 'assets/icons/svg/calendar/warningIcon.svg';
import useAddNewFarmModal from 'components/AddNewFarmModal/hooks/useAddNewFarmModal';
import NewFarmModal from 'components/AddNewFarmModal';
import { useLocation } from 'react-router-dom';
import useOrganizationInfo from './hooks/useOrganizationInfo';
import ConfirmationMessage from 'components/ConfirmationMessage';
import Loader from 'components/Loading';

const FarmRegistration: FC = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    const { t } = useTranslation();
    const { state } = useLocation();
    const navigate = useNavigate();
    if (!state?.name) {
        navigate('/UserFarms')
    }
    const { organizationDetails, selectedOrganization } = useOrganizationInfo();
    const { currentFarmName, handleFarmDescriptionChange, handlePlotTypeSelectionChange, handleFarmLocationChange, farmDescription, handleSubmitPlot,
        addNewPlot, plotsList, handlePlotNameChange, showMoreLanguages, handleShowMoreLanguagesToggle, cropsList, handleSelectedCrop,
        handleDefineFarm, disableDefineFarm, enableDrawing, setEnableDrawing, sendToModalFunction, handlePlotDelete, selectedSoilType, soilTypes, handleSelectedSoil
        , handlePlotEdit, updatePlot, responseMessage, setResponeMessage } = usePanel(t, selectedOrganization);
    const { handleProceed, showModal, farmName, setShowModal,
        onCloseModalHandler, validFarmName, errorMessage, onChangeHandler
    } = useAddNewFarmModal(t, sendToModalFunction);
    const { drawingMethod, setDrawingMethod, acresUsed, mapRef } = useMap(t, plotsList, organizationDetails, addNewPlot, enableDrawing, setEnableDrawing, updatePlot);
    const [showCancelModal, setShowCancelModal] = useState<boolean>();

    if (!cropsList || !organizationDetails) return (<Loader />)

    return (
        <div className='farm-registration-wrapper'>
            <MapView t={t} mapRef={mapRef} acresUsed={acresUsed} enableDrawing={enableDrawing} organizationDetails={organizationDetails} drawingMethod={drawingMethod} setDrawingMethod={setDrawingMethod} />
            <PanelView t={t} handlePlotEdit={handlePlotEdit} showMoreLanguages={showMoreLanguages} handleSubmitPlot={handleSubmitPlot}
                selectedSoilType={selectedSoilType} soilTypes={soilTypes} handleSelectedSoil={handleSelectedSoil}
                handleShowMoreLanguagesToggle={handleShowMoreLanguagesToggle} setShowModal={setShowModal}
                handlePlotTypeSelectionChange={handlePlotTypeSelectionChange} disableDefineFarm={disableDefineFarm}
                handlePlotNameChange={handlePlotNameChange} plotsList={plotsList} enableDrawing={enableDrawing}
                setShowCancelModal={setShowCancelModal} farmDescription={farmDescription}
                farmName={currentFarmName} handleFarmDescriptionChange={handleFarmDescriptionChange}
                handleFarmLocationChange={handleFarmLocationChange} handleDefineFarm={handleDefineFarm}
                cropsList={cropsList} handleSelectedCrop={handleSelectedCrop}
                handlePlotDelete={handlePlotDelete} />
            <Modal show={showCancelModal} closeModal={() => { setShowCancelModal(false) }}>
                <div className={'modal-body-container'}>
                    <img className='cancel-icon' src={warningIcon} />
                    <p className={'cancel-header'}>{t('reportingProcessCancellation')}</p>
                    <p className={'cancel-subheader'}>{t('cancelFarmSubheader')}</p>
                    <ProceedButton disabled={false} handleClick={() => { setShowCancelModal(false); navigate('/UserFarms'); }} title={t('cancelDefinition')} />
                </div>
            </Modal>
            {showModal &&
                <NewFarmModal
                    onCloseModal={onCloseModalHandler}
                    handleProceed={handleProceed}
                    showModal={showModal}
                    farmName={currentFarmName}
                    setShowModal={setShowModal}
                    onCloseModalHandler={onCloseModalHandler}
                    validFarmName={validFarmName}
                    errorMessage={errorMessage}
                    onChangeHandler={onChangeHandler}
                />
            }
            {responseMessage && <ConfirmationMessage
                type={responseMessage.type}
                handleButtonClick={() => setResponeMessage(undefined)}
                messageTitle={responseMessage.messageTitle}
                messageSubtitle={responseMessage.messageSubtitle} />}
        </div>
    )
}

export default FarmRegistration
