import Input from 'components/Input';
import ThePanelView from 'containers/ThePanelView'
import { FC } from 'react'
import { inputPin, descriptionInput } from 'assets/icons/svg/FarmRegistration/index';
import { editIcon } from "assets/icons/svg/constants";
import UnsubmittedPlot from './partials/UnsubmittedPlot';
import { PanelViewProps } from './index.interface';
import SubmittedPlot from './partials/SubmittedPlot';
import ProceedButton from 'components/ProceedButton';
import translateNumbers from 'utils/translateNumbers';
import DropDownSelector from 'components/DropDownSelector';
import { useSelector } from 'react-redux';
import { ReduxState } from 'store';

const PanelView: FC<PanelViewProps> = ({ t, enableDrawing, selectedSoilType, soilTypes, handleSelectedSoil, handlePlotDelete, setShowModal,
    disableDefineFarm, handleDefineFarm, cropsList, handleSubmitPlot, handleSelectedCrop, showMoreLanguages,
    handleShowMoreLanguagesToggle, handlePlotTypeSelectionChange, plotsList, handlePlotEdit,
    handlePlotNameChange, setShowCancelModal, farmDescription, farmName, handleFarmDescriptionChange, handleFarmLocationChange }) => {

    let selectedOrganization = useSelector((reduxState: ReduxState) => reduxState.selectedOrganization)
    const locale = localStorage.getItem('i18nextLng')

    return (
        <div className='farm-registration-panel-container'>
            <ThePanelView title={t('farmConfiguration')} closeAsideBar={() => setShowCancelModal(true)} toggle={true}>
                <div className='panel-subtitle'>{t('farmRegistPanelSub')}</div>
                <div className='panel-content-wrapper' id={'panel-content-wrapper'}>

                    <div className='basic-info-wrapper'>
                        <div className='info-key'>
                            {t('farmOrganization')}
                        </div>
                        <div className='info-value'>
                            {locale === 'en' ? selectedOrganization.nameEn : selectedOrganization.nameAr}
                        </div>
                        <div className='info-key'>
                            {t('farmName')}
                        </div>

                        <div className='info-value'>
                            {farmName}
                            <button
                                className="edit-button"
                                onClick={() => {
                                    setShowModal(true)
                                }}
                            >
                                {editIcon}
                            </button>
                        </div>
                        <div className='line-seperator' />

                        <div className='info-key'>
                            {t('farmLocation')}
                        </div>
                        <div className="location-input">
                            <Input inputType={'text'} title={t('locationName')}
                                handleChange={(e: any) => handleFarmLocationChange(e.target.value)} maxLength={40} iconImage={inputPin}
                            />
                        </div>
                        <div className='info-key'>
                            {t('farmDescription')}
                        </div>
                        <div className='description-input'>
                            <Input inputType={'text'} title={t('farmDescription')} isTextArea={true}
                                handleChange={(e: any) => handleFarmDescriptionChange(e.target.value)} maxLength={40} iconImage={descriptionInput}
                            />
                            {farmDescription && <div className='description-length'>{farmDescription?.length}/{translateNumbers("40")}</div>}
                        </div>
                        {/* <div className='info-key'>
                            {t('soilType')}
                        </div> */}
                        {/* <div className='info-value soil-select'>
                            <DropDownSelector choicesList={soilTypes} currentChoice={selectedSoilType} setCurrentChoice={(choice: string) => handleSelectedSoil("", choice)} />
                        </div> */}
                    </div>
                    <div className='plot-list-wrapper'>
                        {plotsList?.map((plot: any, index: number) => {
                            return plot.properties.status === 'active' ?
                                <UnsubmittedPlot key={plot.id} handlePlotTypeSelectionChange={handlePlotTypeSelectionChange} soilTypes={soilTypes} handleSelectedSoil={handleSelectedSoil}
                                    handlePlotNameChange={handlePlotNameChange} index={index} t={t} plot={plot} handlePlotDelete={handlePlotDelete}
                                    showMoreLanguages={showMoreLanguages} handleShowMoreLanguagesToggle={handleShowMoreLanguagesToggle}
                                    cropsList={cropsList} handleSelectedCrop={handleSelectedCrop} handleSubmitPlot={handleSubmitPlot} />
                                : <SubmittedPlot enableActions={enableDrawing} handlePlotEdit={handlePlotEdit} handlePlotDelete={handlePlotDelete} cropsList={cropsList} index={index} key={plot.id} t={t} plot={plot} />
                        })}
                        {/* 
                        */}
                    </div>
                </div>
                <ProceedButton disabled={disableDefineFarm()} handleClick={() => handleDefineFarm()} title={t('defineFarmBtn')} />
            </ThePanelView >
        </div >
    )
}

export default PanelView
